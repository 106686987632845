import { SET_ME, SET_MY_APPS } from './mutations.types'
import { State } from '@/utils/interfaces'

export const mutations = {
  [SET_ME](state: State, payload: any): void {
    state.me = payload;
  },
  [SET_MY_APPS](state: State, payload: any): void {
    state.myApps = payload;
  },
};
