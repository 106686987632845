import MyApps from "@/views/apps/MyApps.vue";

export const APPS_ROUTES = [
  {
    path: "/my-apps",
    name: "my-apps",
    component: MyApps,
    meta: {
      title: "Início",
      header: true,
      footer: false,
      authentication: true,
    },
  },
];
