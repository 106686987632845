import { getAuthToken } from '@/utils/token'
import axios from './base'

export const getLogs = (payload: any): Promise<Array<any>> => axios.post('/v2/logs', payload)
export const getLogActions = (): Promise<Array<any>> => axios.get('/v2/logs/actions')
export const accessAppLog = (app_id: string | number): Promise<any> => axios.get(`/v2/access/apps/${app_id}`)
export const sendLogout = (): Promise<Array<any>> =>
  axios.post('/user/_logout', {}, {
    headers: {
      'authentication-token': getAuthToken(),
      'Vary': 'Accept-Encoding',
      'Accept': 'application/vnd.api+json',
      'content-type': 'application/vnd.api+json'
    }
  });