import { toLogin } from '@/utils/redirects';
import { getAuthToken } from '@/utils/token'

function changePasswordRedirect(to: any, from: any, next: any) {
  if (from.name == null && to.fullPath.includes('/password/reset?')) {
    next({ 
      name: 'changepassword',
      query: {
        token: window.location.href.split('token=')[1]
      }
    });
  }
}

function setHeaderTitle(to: any, from: any, next: any) {
  document.title = to.meta.title + " - Auth"
  next();
}

function isAuthenticated(from: any, to: any, next:any) {
  const authToken = getAuthToken(); 
  if ((from.name != 'login' || from.path != '/') && !authToken){
    toLogin()
  } else {
    next()
  }
}

export default (to: any, from: any, next: any): void => {
  if (to.meta.authentication === true) isAuthenticated(to, from, next)
  changePasswordRedirect(to, from, next)
  setHeaderTitle(to, from, next)    
};