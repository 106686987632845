import axios from 'axios'
import environment from '@/environment'
import * as Sentry from "@sentry/vue";
import { ServiceAccessError } from '@/utils/error'
import { getAuthToken } from '@/utils/token'
import { APIError } from '@/interfaces/error'; 
import { toLogin } from '@/utils/redirects';

const baseAxios = axios.create({
  baseURL: environment.api,
  withCredentials: true,
})

baseAxios.interceptors.request.use(config => {
  config.headers['authentication-token'] = getAuthToken();
  return config;
});

baseAxios.interceptors.response.use(
  res => res,
  err => {
    if (err.response) {
      const response = err.response;
      if (response.status && (response.status === 401 || response.status === 403) ) {
        if (response.data?.errors?.[0].description != "Password Expired") {
          toLogin('continue=' + encodeURIComponent(window.location.pathname));
        }
      }
    }
    if (err.message === 'Network Error') {
      const message: APIError = {
        url: err.config.baseURL + err.config.url,
        endpoint: err.config.url,
        code: 500
      }
      const error = new ServiceAccessError(message);
      Sentry.captureException(error)       
    }
    return Promise.reject(err);
  }
);

export default baseAxios
