
import { version } from "@/../package.json";
import { Vue, Component } from "vue-property-decorator";
import MainHeader from "./components/Header.vue";
import environment from "@/environment";


@Component({
  components: {
    "main-header": MainHeader,
  },
})
export default class App extends Vue {
  // Versão da aplicação
  version = version;
  environment = environment;

  snackbar = {
    top: false,
    show: false,
    text: false,
    left: false,
    bottom: true,
    right: false,
    absolute: true,
    outlined: false,
    centered: false,
    vertical: false,
    multiLine: false,
    timeout: 5000,
    color: "",
    message: "",
    textStyle: "",
    transition: "fade-transition",
    btn: {
      show: true,
      text: "",
      color: "yellow",
      icon: "mdi-close",
      func: function (config: any) {
        config.show = false;
      },
    },
  };

  showSnackbar(config: any = null) {
    this.snackbar = {
      top: false,
      show: false,
      text: false,
      left: false,
      bottom: true,
      right: false,
      absolute: true,
      outlined: false,
      centered: false,
      vertical: false,
      multiLine: false,
      timeout: 5000,
      color: "",
      message: "",
      textStyle: "",
      transition: "fade-transition",
      btn: {
        show: true,
        text: "",
        color: "yellow",
        icon: "mdi-close",
        func: function (config: any) {
          config.show = false;
        },
      },
    };
    if (config != null) {
      let btn = null;
      if (config.btn) {
        btn = Object.assign(this.snackbar.btn, config.btn);
      }
      this.snackbar = Object.assign(this.snackbar, config);
      if (btn) {
        this.snackbar.btn = btn;
      }
    }
    this.snackbar.show = true;
  }
}
