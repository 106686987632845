
import { getApps } from "@/services/apps";
import { getUsers } from "@/services/user";
import { Vue, Component, Watch } from "vue-property-decorator";
import {
  getRoles,
  saveRoles,
  updateRoles,
  updateRolesUsers,
} from "@/services/roles";
import { orderByName } from "@/utils/sort";
@Component
export default class Permissions extends Vue {
  $refs!: any;
  editId = 0;
  name = "";
  description = "";
  selectedApp = "";
  search: any = "";
  searchUser: any = "";
  apps: any = [];
  users: any = [];
  allUsers: any = [];
  usersSelected: any = [];
  value = null;
  dialog = false;
  loading = false;
  dialogUsers = false;
  loadingTable = true;
  metadata: any | null = null;
  profiles: any = [];
  cmOption: any = {
    tabSize: 4,
    styleActiveLine: false,
    lineNumbers: true,
    styleSelectedText: false,
    line: true,
    foldGutter: true,
    mode: { name: "text/javascript", json: true },
    hintOptions: {
      completeSingle: false,
    },
    matchBrackets: true,
    showCursorWhenSelecting: true,
    theme: "monokai",
    extraKeys: { Ctrl: "autocomplete" },
  };

  headers = [
    {
      text: "Nome",
      align: "start",
      sortable: false,
      value: "name",
      width: "20%",
    },
    {
      text: "Descrição",
      align: "start",
      sortable: false,
      value: "description",
    },
    {
      text: "Aplicação",
      align: "start",
      sortable: false,
      value: "appp",
      width: "15%",
    },
    {
      text: "Usuários",
      align: "center",
      sortable: false,
      value: "profiles",
      width: "15%",
    },
  ];

  get usersFiltered() {
    this.users = this.usersSelected;
    let users = this.allUsers.filter((item: any) => {
      return (
        item.name.toLowerCase().indexOf(this.searchUser.toLowerCase()) > -1
      );
    });
    return users;
  }

  get appsOrdered() {
    return this.apps.sort((a: any, b: any) => {
      let aName: string = a.name;
      let bName: string = b.name;
      return aName.localeCompare(bName, "en");
    });
  }

  async created(): Promise<void> {
    getUsers()
      .then((res: any) => {
        this.allUsers = res.data;
        this.allUsers = orderByName(this.allUsers);
      })
      .catch((error: any) => {
        console.error(error);
        this.$emit("snackbar", {
          message: "Erro ao carregar os usuários!",
        });
      });
    await getApps()
      .then((res: any) => {
        const apps = res.data;
        apps.map((user: any) => {
          this.apps.push(user);
        });
      })
      .catch((error: any) => {
        console.info(error);
        this.$emit("snackbar", {
          message: "Erro ao carregar os aplicativos!",
        });
      });
    await getRoles(null)
      .then((res: any) => {
        this.profiles = res.data;
      })
      .catch((error: any) => {
        console.info(error);
        this.$emit("snackbar", {
          message: "Erro ao carregar as permissões!",
        });
      });
    this.loadingTable = false;

    if (Object.keys(this.$route.query).length !== 0) {
      let id: any = atob(this.$route.query.id + "");
      this.openModal(
        this.profiles.find((p: any) => p.id == id),
        this.$route.query.isUsers == "true" ? true : false
      );
    }
  }

  openModal(profile: any = null, isUsers = false): void {
    if (profile != null) {
      this.addfilters(profile.id, isUsers);
      this.editId = profile.id;
      this.name = profile.name;
      this.description = profile.description;
      if (profile.metadata)
        this.metadata = JSON.stringify(profile.metadata, null, 2);
      if (profile.app != null) this.selectedApp = profile.app.id;
      profile.users.forEach((u: any) => this.users.push(u.id + ""));

      if (isUsers) this.usersSelected = [...this.users];
    }
    isUsers ? (this.dialogUsers = true) : (this.dialog = true);
  }

  closeModal(isUsers = false): void {
    if (this.$route.fullPath != this.$route.path) this.setQuery();
    this.editId = 0;
    setTimeout(() => {
      this.name = "";
      this.searchUser = "";
      this.description = "";
      this.selectedApp = "";
      this.users = [];
      this.usersSelected = [];
      if (!isUsers) this.$refs.form.reset();
    }, 290);
    isUsers ? (this.dialogUsers = false) : (this.dialog = false);
  }

  save(): void {
    if (!this.$refs.form.validate()) return;
    if (this.editId != 0) {
      updateRoles(this.editId, {
        name: this.name,
        description: this.description,
        app: this.selectedApp,
        metadata: JSON.parse(this.metadata) || undefined,
      })
        .then(() => {
          this.profiles.find((profile: any) => {
            if (profile.id == this.editId) {
              profile.name = this.name;
              profile.description = this.description;
              profile.app = this.apps.find(
                (a: any) => a.id == this.selectedApp
              );
            }
          });

          this.closeModal();
          this.$emit("snackbar", {
            message: "Editada com sucesso!",
          });
        })
        .catch((error) => {
          console.error(error);
          this.$emit("snackbar", {
            message: "Erro ao editar!",
          });
        });
    } else {
      saveRoles({
        name: this.name,
        description: this.description,
        app: this.selectedApp,
        metadata: JSON.parse(this.metadata) || undefined,
      })
        .then(() => {
          getRoles(null)
            .then((res: any) => {
              this.profiles = res.data;
            })
            .catch((error: any) => {
              console.info(error);
              this.$emit("snackbar", {
                message: "Erro ao carregar as permissões!",
              });
            })
            .finally(() => {
              this.loadingTable = false;
            });
          this.closeModal();
          this.$emit("snackbar", {
            message: "Criada com sucesso!",
          });
        })
        .catch((error) => {
          console.error(error);
          this.$emit("snackbar", {
            message: "Erro ao criar!",
          });
        });
    }
  }

  saveUsers(): void {
    this.loading = true;
    updateRolesUsers(this.editId, { users: this.users })
      .then(() => {
        this.profiles.find((profile: any) => {
          if (profile.id == this.editId) {
            let newUsers: any = [];
            this.users;
            this.allUsers.forEach((user: any) => {
              if (this.users.indexOf(user.id) != -1) newUsers.push(user);
            });
            profile.users = newUsers;
            this.closeModal(true);
          }
        });
      })
      .catch((error) => {
        console.error(error);
        this.$emit("snackbar", {
          message: "Erro ao editar os usuários!",
        });
      })
      .finally(() => (this.loading = false));
  }

  addfilters(id: any, isUsers: boolean): void {
    if (this.$route.fullPath == this.$route.path) {
      let query: any = {
        id: btoa(id),
        isUsers: isUsers,
      };
      this.setQuery(query);
    }
  }

  setQuery(query: any = {}): void {
    let name: any = this.$route.name;
    this.$router.replace({
      name: name,
      query: query,
    });
  }

  changeUser(id: any): void {
    if (this.usersSelected.indexOf(id) > -1) {
      this.usersSelected.splice(this.usersSelected.indexOf(id), 1);
    } else {
      this.usersSelected.push(id);
    }
  }
}
