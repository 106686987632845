import axios from './base'

export const getUsers = (fields?: string[]): Promise<unknown> => {
  const query = {
    params: {
      fields:fields ? fields.join(',') : null
    }
  }
  return axios.get('/v2/users', query)
}
export const isAdmin = (): Promise<unknown> => axios.get(`/v2/users/me/profiles`)
export const createUser = (user: any): Promise<unknown> => axios.post('/v1/user/_signup', user)
export const updateUser = (id: string, user: any): Promise<unknown> => axios.put(`/v2/users/${id}`, user)
export const expirePassword = (id: string): Promise<unknown> => axios.get(`/v2/users/${id}/expire`)
export const resetPassword = (email: string): Promise<unknown> => axios.post(`/v1/user/password/_reset`, { email: email } )
export const twoFA = (id: string): Promise<unknown> => axios.patch(`/v2/users/${id}/2fa`)
export const twoFaByUser = (): Promise<unknown> => axios.get(`/v1/user/2fa/_create`)
export const activate2FaByUser = (payload: any): Promise<unknown> => axios.post(`/v1/user/2fa/_activate`, payload)  
export const changePassword = (payload: any): Promise<unknown> => axios.post(`/v1/user/password/_change`, payload)  
export const updateProfilePhoto = (payload: any): Promise<unknown> => axios.patch(`/v2/users/me/profile_photo`, payload)  
