<template>
  <v-row>
    <v-col cols="12" class="text-center" v-if="loading">
      <v-progress-circular
        indeterminate
        color="yellow"
        style="margin-top: 90px"
        :size="50"
      ></v-progress-circular>
    </v-col>
    <v-col cols="12" class="text-center" v-else-if="!apps.length">
      <h2
        style="
          color: rgb(255 255 255 / 50%);
          font-weight: normal;
          margin-top: 100px;
        "
      >
        Não há aplicativos
      </h2>
    </v-col>
    <v-col cols="12" v-if="apps">
      <div class="d-flex all-cards flex-wrap">
        <cards v-for="app of apps" v-show="app.id" :key="app.id" :app="app" />
      </div>
    </v-col>
  </v-row>
</template>
<script>
import { Vue, Component } from "vue-property-decorator";
import Cards from "./components/Cards.vue";
import { mapActions, mapGetters } from "vuex";

const MAX_ATTEMPTS = 10;

@Component({
  components: {
    cards: Cards,
  },
  computed: {
    ...mapGetters("meState", {
      me: "me",
    }),
  },
  watch: {
    apps() {
      const app_order = [];
      this.apps.map((app) => {
        if (app && app.id) app_order.push(app.id);
      });
      this.setCookie("app_order", app_order, 365);
    },
  },
  methods: {
    ...mapActions("meState", {
      getMyApps: "actionsMyApps",
    }),

    setCookie(cname, cvalue, exdays) {
      const d = new Date();
      d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
      let expires = "expires=" + d.toUTCString();
      document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    },

    isMobile() {
      if (
        this.$vuetify.breakpoint.name == "xs" ||
        this.$vuetify.breakpoint.name == "sm"
      ) {
        return true;
      }
      return false;
    },

    getCookie(cname) {
      let name = cname + "=";
      let decodedCookie = decodeURIComponent(document.cookie);
      let ca = decodedCookie.split(";");
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == " ") {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    },

    async getApps() {
      if (this.me && this.me.id) {
        try {
          this.apps = await this.getMyApps(this.me.id);
          const app_order = this.getCookie("app_order").split(",");
          const new_order = [];
          app_order.forEach((id) => {
            const app = this.apps.find((app) => app.id == id);
            if (app) new_order.push(app);
          });
          this.apps.forEach((app) => {
            const clientId = localStorage.getItem("client_id");
            if (app.client_id && app.client_id == clientId) {
              localStorage.removeItem("client_id");
              window.location.href = app.login_url;
            }
            if (app_order.indexOf(app.id) == -1) {
              new_order.push(app);
            }
          });
          this.apps = new_order;
        } catch (error) {
          console.error(error);
          this.$emit("snackbar", {
            message: "Erro ao carregar os aplicativos!",
          });
        } finally {
          this.loading = false;
        }
      } else {
        if (this.attempts <= MAX_ATTEMPTS) {
          this.attempts++;
          setTimeout(() => {
            this.getApps();
          }, 1000);
        } else {
          this.loading = false;
          this.$emit("snackbar", {
            message: "Erro ao carregar os aplicativos!",
          });
        }
      }
    },
  },
  async created() {
    this.getApps();
  },
  data() {
    return {
      attempts: 1,
      apps: [],
      order: [],
      drag: true,
      loading: true,
    };
  },
})
export default class MyApps extends Vue {}
</script>
<style lang="scss" scoped>
.all-cards {
  justify-content: center;
  @media (max-width: 1000px) {
    flex-direction: column;
  }
}
.ghost {
  opacity: 1;
  border-radius: 5px;
  .editor-body {
    opacity: 0.3;
  }
}
.flip-list-move {
  transition: transform 0.5s;
}
</style>
