
import { changePassword } from "@/services/user";
import { toLogin } from "@/utils/redirects";
import { AxiosError } from "axios";
import { Component, Vue } from "vue-property-decorator";

@Component
export default class ChangePasswordForm extends Vue {
  $refs!: any;
  $emit: any;
  password = "";
  confirmPassword = "";
  value = 0;
  showConfirmPass = false;
  showPass = false;
  token: any = "";
  error4017 = false;
  loadingSend = false
  roles = [
    (v: string): unknown => this.isEmpty(v),
    (v: string): unknown => this.isUpperCase(v),
    (v: string): unknown => this.isLowerCase(v),
    (v: string): unknown => this.isNumber(v),
    (v: string): unknown => this.getSpecialCaracter(v),
    (v: string): unknown => this.isLengthTrue(v),
  ];

  created(): void {
    this.token = this.$route.query.token;
  }

  login(): void {
    toLogin();
  }

  save(): void {
    if (!this.$refs.form.validate()) return;
    const payload = {
      new_password: this.password,
      token: this.token,
    };
    this.loadingSend = true
    changePassword(payload)
      .then(() => {
        this.$emit("showSnackbar", {
          text: "Senha alterada com sucesso",
        });
        this.error4017 = false
        toLogin()
      })
      .catch((err: AxiosError) => {
        const error4017 = err.response?.data.errors.find((error: any )=> error.code == 4017)
        if (error4017) {
          this.error4017 = true
        }
        console.info(err);
        this.$emit("showSnackbar", {
          text: "Não foi possível alterar a senha",
        });
      }).finally(() => {
        this.loadingSend = false
      });
  }

  get progress(): number {
    return this.value;
  }

  get color(): string {
    return ["error", "warning", "success"][Math.floor(this.progress / 40)];
  }

  get colorInput(): string {
    if (this.progress == 0) return "yellow";
    return ["error", "warning", "success"][Math.floor(this.progress / 40)];
  }

  isEmpty(value: string): any {
    return !!value;
  }

  lengthTrue = false;
  isLengthTrue(str: string): boolean {
    const response = str.length >= 8;

    if (response && !this.lengthTrue) {
      this.value += 20;
      this.lengthTrue = true;
    } else if (!response && this.lengthTrue) {
      this.value -= 20;
      this.lengthTrue = false;
    }
    return response;
  }

  upperTrue = false;
  isUpperCase(str: string): boolean {
    const result = str.split("").filter((letter: string) => /[A-Z]/.test(letter)).length;

    const response = parseInt(result + "") >= 1;

    if (response && !this.upperTrue) {
      this.value += 20;
      this.upperTrue = true;
    } else if (!response && this.upperTrue) {
      this.value -= 20;
      this.upperTrue = false;
    }
    return response;
  }

  lowerTrue = false;
  isLowerCase(str: string): boolean {
    const result = str.split("").filter((letter: string) => /[a-z]/.test(letter)).length;

    const response = parseInt(result + "") >= 1;

    if (response && !this.lowerTrue) {
      this.value += 20;
      this.lowerTrue = true;
    } else if (!response && this.lowerTrue) {
      this.value -= 20;
      this.lowerTrue = false;
    }

    return response;
  }

  numberTrue = false;
  isNumber(str: string): boolean {
    const result = str.split("").filter((letter: string) => /[0-9]/.test(letter)).length;

    const response = parseInt(result + "") >= 1;

    if (response && !this.numberTrue) {
      this.value += 20;
      this.numberTrue = true;
    } else if (!response && this.numberTrue) {
      this.value -= 20;
      this.numberTrue = false;
    }

    return response;
  }

  scTrue = false;
  getSpecialCaracter(str: string): boolean {
    let regex = /^(?=.*[@!#$%^&*()/\\])[@!#$%^&*()/\\a-zA-Z0-9]$/;
    const result = str.split("").filter((letter: string) => regex.test(letter)).length;

    const response = parseInt(result + "") >= 1;

    if (response && !this.scTrue) {
      this.value += 20;
      this.scTrue = true;
    } else if (!response && this.scTrue) {
      this.value -= 20;
      this.scTrue = false;
    }

    return response;
  }
}
