import Vue from 'vue'
import beforeEach from './beforeEach';
import VueRouter, { RouteConfig } from 'vue-router'
import { APPS_ROUTES } from '@/views/apps/routes'
import { ADMIN_ROUTES } from '@/views/admin/routes'
import { LOGIN_ROUTES } from '@/views/login/routes'
import { CHANGE_PASSWORD_ROUTES } from '@/views/changepassword/routes'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  ...APPS_ROUTES, 
  ...ADMIN_ROUTES, 
  ...LOGIN_ROUTES,
  ...CHANGE_PASSWORD_ROUTES, 
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(beforeEach);

export default router
