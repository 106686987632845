import Apps from "@/views/admin/apps/Apps.vue";
import Logs from "@/views/admin/logs/Logs.vue";
import Profiles from "@/views/admin/profiles/Profile.vue";
import Users from "@/views/admin/user/Users.vue";
import Permissions from "@/views/admin/permissions/Permissions.vue";

export const ADMIN_ROUTES = [
  {
    path: "/admin/apps",
    name: "apps",
    component: Apps,
    meta: {
      title: "Aplicativos",
      header: true,
      footer: false,
      authentication: false,
    },
  },
  {
    path: "/admin/profiles",
    name: "profiles",
    component: Profiles,
    meta: {
      title: "Perfis",
      header: true,
      footer: false,
      authentication: true,
    },
  },
  {
    path: "/admin/users",
    name: "users",
    component: Users,
    meta: {
      title: "Usuários",
      header: true,
      footer: false,
      authentication: true,
    },
  },
  {
    path: "/admin/permissions",
    name: "permissions",
    component: Permissions,
    meta: {
      title: "Permissões",
      header: true,
      footer: false,
      authentication: true,
    },
  },
  {
    path: "/admin/logs",
    name: "logs",
    component: Logs,
    meta: {
      title: "Logs",
      header: true,
      footer: false,
      authentication: true,
    },
  },
];
