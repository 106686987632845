import { IUsersRequest } from "@/utils/interfaces";
import { getUsers } from "@/services/user";
import { SET_USERS } from "./mutations.types";

export const actions = {
  actionsSetUsers: ({ commit }: any, users: any): void => {
    commit(SET_USERS, users);
  },

  actionsUsers: ({ dispatch }: any, fields?: string[]): any => {
    return getUsers(fields).then((user: IUsersRequest | any) => {
      dispatch("actionsSetUsers", user.data);
      return user.data;
    });
  },
};
