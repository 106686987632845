import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import './assets/styles/global.scss'
import filters from '@/utils/filters'
import * as Sentry from "@sentry/vue";
import { Vue as VueIntegration } from "@sentry/integrations";
import { name, version } from '../package.json';
import './plugins/codemirror'

console.log("Versão:", version);
console.log("Ambiente:", process.env.NODE_ENV);

if (process.env.NODE_ENV === 'production') {
  console.debug("Configurando o Sentry");

  // Integração com o Sentry
  Sentry.init({
    dsn: 'https://a2663951038d461896bc77b717921d52@o92838.ingest.sentry.io/5974914',
    environment: (window.location.host.indexOf('.quiteja.com.br') !== -1) ? 'production' : 'staging',
    integrations: [
      new VueIntegration({
        Vue,
        attachProps: true,
        logErrors: true,
      }),
    ],
    release: name + '@' + version,
  });
}

Vue.config.productionTip = false
Vue.mixin({ filters })

new Vue({
  router,
  store,
  vuetify,
  filters,
  render: h => h(App)
}).$mount('#app')
