
import { Vue, Component, Watch } from "vue-property-decorator";
import { getApps } from "@/services/apps";
import { changeApp, createApp, updateAppPhoto } from "@/services/apps";
import { postFile, uploadAWS } from "@/services/file";

@Component
export default class Apps extends Vue {
  search: any = "";
  apps: any = [];
  loadingTable = true;
  headers = [
    { value: "color", width: "1%" },
    { text: "Nome", value: "name", width: "20%" },
    { text: "Descrição", value: "description" },
    { text: "URL login", value: "login_url", width: "39%" },
  ];

  // Props do Formulário
  app_name = "";
  name = "";
  desc = "";
  login_url = "";
  id = "";
  client_id = "";
  redirect_uri = "";
  disableFields = false;
  dialog = false;
  createForm = false;
  photo: any = {
    url: "",
  };
  file: any = null;

  // Color Picker
  color = "#1976D2FF";
  mask = "!#XXXXXXXX";
  menu = false;

  dialogCropper = false;
  tempImg: any = "";
  changeFile: any = false;
  img: any = "";

  async created(): Promise<void> {
    await getApps()
      .then((apps: any) => {
        this.apps = apps.data;
      })
      .catch((error: any) => {
        console.error(error);
        if (error.message.includes("403")) this.$router.push({ name: "login" });
        this.$emit("snackbar", {
          message: "Erro ao carregar os aplicativos!",
        });
      });

    this.loadingTable = false;

    if (Object.keys(this.$route.query).length !== 0) {
      let id: any = atob(this.$route.query.id + "");
      this.setDataOnForm(this.apps.find((a: any) => a.id == id));
    }
  }

  get swatchStyle(): any {
    const { color, menu } = this;
    return {
      backgroundColor: color,
      cursor: "pointer",
      height: "30px",
      width: "30px",
      borderRadius: menu ? "50%" : "4px",
      transition: "border-radius 200ms ease-in-out",
    };
  }

  @Watch("file")
  onPropertyChanged(): void {
    if (this.changeFile) {
      this.changeFile = false;
      return;
    }
    if (this.file == null) return;
    var reader = new FileReader();
    reader.readAsDataURL(this.file);
    reader.onload = () => {
      if (reader.result) {
        this.img = reader.result;
        this.dialogCropper = true;
        let inputFileHeader: any = document.getElementById("inputFile");
        inputFileHeader.value = "";
      }
    };
  }

  openFile(): void {
    document.getElementById("inputFile")?.click();
  }

  closeForm(): void {
    if (this.$route.fullPath != this.$route.path) this.setQuery();
    this.dialog = false;
    this.name = "";
    this.app_name = "";
    this.desc = "";
    this.login_url = "";
    this.id = "";
    this.client_id = "";
    this.redirect_uri = "";
    this.photo = {
      url: "",
    };
    this.file = null;
  }

  openCreateDialog(): void {
    this.dialog = true;
    this.createForm = true;
    this.disableFields = false;
  }

  setDataOnForm(form: any): void {
    this.addfilters(form.id);
    this.createForm = false;
    this.dialog = true;
    this.disableFields = true;
    this.name = form.name;
    this.app_name = form.name;
    this.desc = form.description;
    this.login_url = form.login_url;
    this.client_id = form.client_id;
    this.color = form.color ? form.color : "#E5EE04FF";
    this.id = form.id;
    this.redirect_uri = form.redirect_uri;
    if (form.app_photo) this.photo = form.app_photo;
  }

  save(): void {
    const updatedApp = {
      name: this.name,
      description: this.desc,
      login_url: this.login_url,
      client_id: this.client_id,
      redirect_uri: this.redirect_uri,
      color: this.color,
    };
    this.createForm
      ? createApp(updatedApp)
          .then((res: any) => {
            if (this.file != null) {
              const payloadFile = {
                folders: ["apps", "photo"],
                original_name: this.file.name,
                content_type: this.file.type,
                size: this.file.size,
              };
              postFile(payloadFile)
                .then((fileAWS: any) => {
                  let formData = new FormData();
                  formData.append("Content-Type", fileAWS.data.content_type);
                  formData.append("key", fileAWS.data._storage.fields.key);
                  formData.append(
                    "AWSAccessKeyId",
                    fileAWS.data._storage.fields.AWSAccessKeyId
                  );
                  formData.append("acl", fileAWS.data._storage.fields.acl);
                  formData.append("policy", fileAWS.data._storage.fields.policy);
                  formData.append("signature", fileAWS.data._storage.fields.signature);
                  formData.append("file", this.file);
                  uploadAWS(fileAWS.data._storage.url, formData).then(() => {
                    this.photo.url = fileAWS.data.url;
                    updateAppPhoto(this.id, { app_photo_id: fileAWS.data.id });
                  });
                })
                .catch((err: any) => console.info(err));
            }
            this.dialog = false;
            this.apps.push(updatedApp);
            this.$emit("snackbar", {
              message: "App foi criado!",
            });
          })
          .catch((err: any) => {
            console.info(err);
          })
      : changeApp(this.id, updatedApp)
          .then((res: any) => {
            this.apps.map((app: any) => {
              if (app.id == this.id) {
                app.name = updatedApp.name;
                app.description = updatedApp.description;
                app.login_url = updatedApp.login_url;
                app.redirect_uri = updatedApp.redirect_uri;
              }
            });
            if (this.file != null) {
              const payloadFile = {
                folders: ["apps", "photo"],
                original_name: this.file.name,
                content_type: this.file.type,
                size: this.file.size,
              };
              postFile(payloadFile)
                .then((fileAWS: any) => {
                  let formData = new FormData();
                  formData.append("Content-Type", fileAWS.data.content_type);
                  formData.append("key", fileAWS.data._storage.fields.key);
                  formData.append(
                    "AWSAccessKeyId",
                    fileAWS.data._storage.fields.AWSAccessKeyId
                  );
                  formData.append("acl", fileAWS.data._storage.fields.acl);
                  formData.append("policy", fileAWS.data._storage.fields.policy);
                  formData.append("signature", fileAWS.data._storage.fields.signature);
                  formData.append("file", this.file);
                  uploadAWS(fileAWS.data._storage.url, formData).then(() => {
                    this.photo.url = fileAWS.data.url;
                    updateAppPhoto(this.id, { app_photo_id: fileAWS.data.id });
                  });
                })
                .catch((err: any) => console.info(err));
            }
            this.$emit("snackbar", {
              message: "App foi atualizado!",
            });
            console.info(res);
            this.dialog = false;
          })
          .catch((err: any) => {
            console.info(err);
          });
  }

  addfilters(id: any): void {
    if (this.$route.fullPath == this.$route.path) {
      let query: any = {
        id: btoa(id),
      };
      this.setQuery(query);
    }
  }

  setQuery(query: any = {}): void {
    let name: any = this.$route.name;
    this.$router.replace({
      name: name,
      query: query,
    });
  }

  change({ canvas }: any): void {
    this.tempImg = canvas.toDataURL();
  }

  changeImg(): void {
    this.changeFile = true;
    this.photo.url = this.tempImg;
    this.file = this.dataURLtoFile(this.tempImg);
    this.dialogCropper = false;
  }

  dataURLtoFile(dataurl: any): any {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], this.file.name, { type: mime });
  }
}
