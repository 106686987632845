import { getLogs } from "@/services/logs";
import { SET_LOGS } from "./mutations.types";

export const actions = {
  actionsSetLogs: ({ commit }: any, logs: any): void => {
    commit(SET_LOGS, logs);
  },

  actionsLogs: ({ dispatch }: any, payload: any): any => {
    return getLogs(payload).then((logs: any) => {
      dispatch("actionsSetLogs", logs.data);
      return logs.data;
    });
  },
};
