import Login from "@/views/login/Login.vue";
import { getAuthToken, removeAuthToken } from "@/utils/token";
import environment from "@/environment";


export const LOGIN_ROUTES = [
  {
    path: "/",
    name: "redirect-to-login",
    redirect: { name: "my-apps" },
    meta: {
      title: "Login",
      footer: true,
      header: false,
      authentication: false,
    },
  },
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: {
      title: "Login",
      footer: true,
      header: false,
      authentication: false,
    },
  },
  {
    path: "/oauthconfirm",
    beforeEnter: (to: any, from: any, next: any) => {
      const params: any = {
        auth_token: getAuthToken(),
        response_type: to.query.response_type,
        client_id: to.query.client_id,
        redirect_uri: to.query.redirect_uri,
        scope: to.query.scope,
        confirm: "yes",
      };
      
      if (to.query.force_login == "true") {
        localStorage.removeItem("2fa");
        removeAuthToken();
        window.location.href = "/login";
        return;
      }

      if (!params['auth_token']) localStorage.setItem('client_id', to.query.client_id)

      const url = `${environment.api}/api/oauth/authorize?${new URLSearchParams(params).toString()}`;

      window.location.href = url;
    },
    meta: {
      title: "oauth",
      header: false,
      authentication: false,
    },
  },
  {
    path: "/unauthorized",
    beforeEnter: (to: any, from: any): void => {
      window.location.href = "/login";
    },
    meta: {
      title: "unauthorized",
      header: false,
      authentication: false,
    },
  },
];
