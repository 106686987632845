import { actions } from './actions'
import { mutations } from './mutations'
import { getters } from './getters'

export default {
  namespaced: true,
  state: {
    me: {
      _2fa: "TOTP",
    },
    myApps: []
  },
  getters: getters,
  actions: actions,
  mutations: mutations
}