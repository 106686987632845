
import { Component, Vue, Prop } from "vue-property-decorator";
import { accessAppLog } from "@/services/logs";

@Component
export default class Cards extends Vue {
  @Prop() readonly app: any;

  logApp(): void {
    accessAppLog(this.app.id)
      .then(() => window.open(this.app.login_url, "_blank"))  
  }
}
