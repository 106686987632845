
import { signin } from "@/services/auth";
import { removeAuthToken } from "@/utils/token";
import { Vue, Component } from "vue-property-decorator";
import { SET_AUTH_TOKEN } from "../../store/mutations.types";
import { resetPassword } from "@/services/user";
import { Mutation } from "vuex-class";
import { AxiosError } from "axios";
import environment from "@/environment";

@Component
export default class UserAuth extends Vue {
  $refs!: HTMLFormElement;
  setLoadingShow: any;
  environment = environment;
  show = false;
  resetForm = false;
  email = "";
  password = "";
  loading = false;
  valid = true;
  rules = {
    required: (value: string): unknown => !!value || "Campo é necessário",
    email: (value: string): unknown => {
      const pattern =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return pattern.test(value) || "Email inválido";
    },
  };

  @Mutation(SET_AUTH_TOKEN, { namespace: "loginState" })
  setAuthToken!: (token: string | null) => void;

  resetPassword(email: string): void {
    if (!this.$refs.form.validate()) return;
    resetPassword(email)
      .then(() => {
        this.$emit("snackbar", {
          message: "E-mail foi enviado!",
        });
        this.resetForm = false;
      })
      .catch((error: AxiosError) => {
        console.info(error);
        this.$emit("snackbar", {
          message: "Erro ao enviar reset de senha!",
        });
      });
  }

  /*
   * Caso o usuário esteja logado e volte
   * o token é removido
   */
  mounted(): void {
    removeAuthToken();
    this.setAuthToken(null);
  }

  changeResetFormState(value: boolean): void {
    this.resetForm = value;
  }

  setIcon(show: boolean): string {
    return show ? "mdi-eye" : "mdi-eye-off";
  }

  changeInputType(show: boolean): string {
    return show ? "text" : "password";
  }

  isFormValid(): boolean {
    return this.email != "" && this.password != "";
  }

  sendToChangePassword(token: string): void {
    this.$router.push({
      name: "changepassword",
      query: { token: token },
    });
  }

  sendToValidate2FA(token: string, ask_for: string): void {
    this.setAuthToken(token);
    localStorage.setItem("2fa", ask_for);
  }

  sendToMyApps(token: string): void {
    this.setAuthToken(token);
    this.$router.push({
      name: "my-apps",
    });
  }

  async sendUserCredentials(email: string, password: string): Promise<void> {
    this.loading = true;
    if (this.$refs.form.validate()) {
      const loginResponse: any = await signin({
        email: email,
        password: password,
      })
        .then((response) => response)
        .catch((e: AxiosError) => {
          this.$emit("snackbar", {
            message: "Erro ao tentar se conectar com o servidor!",
          });
          if (e.response?.status === 429) {
            this.$emit("snackbar", {
              message: "Muitas tentativas, aguarde alguns instantes",
            });
            return;
          }
          if (e.response) {
            const err = e.response?.data?.errors[0];
            if (err.description == "Password Expired") {
              this.$router.push({
                name: "changepassword",
                query: { token: err.data.token_change_password },
              });
            }
          }
          e.message === "Network Error"
            ? this.$emit("snackbar", {
                message: "Erro ao tentar se conectar com o servidor!",
              })
            : this.$emit("snackbar", {
                top: true,
                multiLine: true,
                color: "red",
                textStyle: "font-weight: 600;",
                transition: "scroll-y-transition",
                message: "Usuário ou senha inválidos!",
                btn: {
                  color: "white",
                },
              });
        })
        .finally(() => (this.loading = false));

      if (loginResponse) {
        this.setAuthToken(loginResponse.data.token);
        if (!("ask_for" in loginResponse.data)) {
          localStorage.setItem("2fa", "false");
          if (this.$route.query.continue) {
            const query: any = this.$route.query;
            this.$router.push({ path: decodeURIComponent(query.continue) });
          } else {
            this.$router.push({ name: "my-apps" });
          }
        }
        if (loginResponse.status == 401) {
          this.$emit("snackbar", {
            top: true,
            multiLine: true,
            color: "red",
            textStyle: "font-weight: 600;",
            transition: "scroll-y-transition",
            message: "Usuário ou senha inválidos!",
            btn: {
              color: "white",
            },
          });
        }

        if (loginResponse.status == 200) {
          if (loginResponse.data.ask_for == "change_password")
            this.sendToChangePassword(loginResponse.data.token);
          else if (loginResponse.data.ask_for == "validate_2fa")
            this.sendToValidate2FA(
              loginResponse.data.token,
              loginResponse.data.ask_for
            );
          else if (!("ask_for" in loginResponse.data))
            this.sendToMyApps(loginResponse.data.token);
        }
      }

      this.loading = false;
    } else {
      this.loading = false;
    }
  }
}
