
import GoogleAuth from './forms/GoogleAuth.vue'
import UserAuth from './forms/UserAuth.vue'
import { Vue, Component, Watch } from 'vue-property-decorator'
import { Getter } from 'vuex-class'

@Component({
  components: { 
    'google-auth': GoogleAuth,
    'user-auth': UserAuth,
  },
})
export default class Form extends Vue {
  email = ''
  password = ''
  show = false

  @Getter('authToken', { namespace: 'loginState' })
  authToken!: string

  @Watch('authToken')
  onTokenChange(): void {
    this.show = this.authToken && (localStorage.getItem("2fa") != 'false') ? true : false
  }
}
