import { getAuthToken } from '@/utils/token'
import { getters } from './getters'
import { mutations } from './mutations'

export default {
  namespaced: true,
  state: {
    authToken: getAuthToken(),
  },
  getters: getters,
  mutations: mutations
}