import { getAuthToken } from '@/utils/token'
import { State } from '@/utils/interfaces'

export const getters = {
  authToken: (state: State): string | null => {
    if (state.authToken) {
      return state.authToken;
    } else {
      return getAuthToken();
    }
  },
};
