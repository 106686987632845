import axios from './base'

export const saveRoles = (payload: any): Promise<any> => axios.post(`/v2/roles`, payload);
export const updateRoles = (id: any, payload: any): Promise<any> => axios.put(`/v2/roles/` + id, payload);
export const updateRolesUsers = (id: any, payload: any): Promise<any> => axios.patch("/v2/roles/" + id + "/users", payload);
export function getRoles(fields: string[] | null ): Promise<any> {
  const endpoint = fields ? `/v2/roles?fields=${fields.join(',')}` : '/v2/roles'
  return axios.get(endpoint);
}

