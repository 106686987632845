import { SET_ME, SET_MY_APPS } from './mutations.types'
import { getUserMe } from '@/services/me'
import { myApps } from '@/services/apps'
import * as Sentry from "@sentry/vue";
import { toLogin } from '@/utils/redirects';

export const actions = {
  actionsSetMe: ({ commit }: any, me: any): void => {
    commit(SET_ME, me)
  },

  actionsMe: ({ dispatch }: any): any => {
    return getUserMe()
      .then((me: any) => {
        dispatch('actionsSetMe', me.data)
        if (me.data.id) {
          Sentry.setUser({
            id: me.data.id,
            email: me.data.email,
            username: me.data.name,
          });
        }
        return me.data
      })
      .catch(() => {
        toLogin()
      })
  },

  actionsSetMyApps: ({ commit }: any, myapps: any): void => {
    commit(SET_MY_APPS, myapps)
  },

  actionsMyApps: ({ dispatch }: any, user_id: string): any => {
    return myApps(user_id)
      .then((myApps: any) => {
        dispatch('actionsSetMyApps', myApps.data)
        return myApps.data
      })
  }
}
