
import { Component, Vue } from "vue-property-decorator";
import Form from "./components/Form.vue";

@Component({
  components: {
    "form-login": Form,
  },
})
export default class Login extends Vue {}
