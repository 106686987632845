import ChangePasswordForm from "@/views/changepassword/ChangePasswordForm.vue";

export const CHANGE_PASSWORD_ROUTES = [
  {
    path: "/changepassword",
    name: "changepassword",
    component: ChangePasswordForm,
    meta: {
      title: "Alterar Senha",
      header: false,
      footer: false,
      authentication: false,
    },
  },
];
