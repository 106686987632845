import VueCookies from 'vue-cookies'

declare global {
  interface Window {
    $cookies: any;
  }
}
window.$cookies = VueCookies
export function saveAuthToken(token: string): void {
  localStorage.setItem('AUTH_TOKEN', token)
  window.$cookies.set('AUTH_TOKEN', token)
}

export function getAuthToken(): string | null {
  console.log(window)
  return window.$cookies.get('AUTH_TOKEN')
}

export function removeAuthToken(): void {
  window.$cookies.remove('AUTH_TOKEN')
}