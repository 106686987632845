import Vue from 'vue'
import Vuex from 'vuex'
import loginState from '@/views/login/store/login.module'
import meState from '@/views/apps/store/me.module'
import usersState from '@/views/admin/user/store/users.module'
import profileState from '@/views/admin/profiles/store/profiles.module'
import logsState from '@/views/admin/logs/store/logs.module'
import appsState from '@/views/admin/apps/store/apps.module'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    loginState,
    meState,
    usersState,
    appsState,
    profileState,
    logsState
  }
})
