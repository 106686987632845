import { saveAuthToken } from '@/utils/token'
import { SET_AUTH_TOKEN } from './mutations.types'
import { State } from '@/utils/interfaces'

export const mutations = {
  [SET_AUTH_TOKEN](state: State, authToken: string): void {
    state.authToken = authToken;
    if (authToken) saveAuthToken(authToken);
  },
};
