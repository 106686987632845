export const verifyDueDate = (date: string): any => {
    const passDueDay = new Date(date).getUTCDate()
    const passDueMonth = new Date(date).getUTCMonth() + 1
    const passDueYear = new Date(date).getUTCFullYear()
    
    const today = new Date()

    const year = today.getFullYear()
    const month = today.getMonth() + 1
    const day = today.getDate() 

    return {
        expired: (day - passDueDay) > -8 && (month - passDueMonth) == 0 && (year - passDueYear) == 0,
        expire_in: ((day - passDueDay) + '').replace('-', '')
    }
}