
import { Component, Vue, Watch } from "vue-property-decorator";
import { validateTwoFaToken } from "@/services/auth";
import { removeAuthToken, saveAuthToken } from "@/utils/token";
import { SET_AUTH_TOKEN } from "../../store/mutations.types";
import { twoFaByUser, activate2FaByUser } from "@/services/user";
import { Mutation } from "vuex-class";

@Component
export default class GoogleAuth extends Vue {
  twoFaToken = "";
  loading = false;
  document!: string | number;
  $refs!: HTMLFormElement;
  qrCode = "";
  totp_key = "";

  created(): void {
    twoFaByUser()
      .then((res: any) => {
        this.qrCode = res.data.qr_code;
        this.totp_key = res.data.totp_key;
      })
      .catch(() => {
        this.$emit("snackbar", {
          message: "Não foi possível obter o QR code!",
        });
      });
  }

  @Watch("twoFaToken")
  async twoFaTokenWatch(val: number): Promise<void> {
    if (val.toString().length === 6) this.validate(this.twoFaToken);
  }

  @Mutation(SET_AUTH_TOKEN, { namespace: "loginState" })
  setAuthToken!: (token: string | null) => void;

  get is2faRegistered(): boolean {
    return localStorage.getItem("2fa") === "register_2fa";
  }

  get isMobile(): boolean {
    if (
      this.$vuetify.breakpoint.name == "xs" ||
      this.$vuetify.breakpoint.name == "sm"
    ) {
      return true;
    }
    return false;
  }

  backToLoginCredentials(): void {
    removeAuthToken();
    this.setAuthToken(null);
  }

  sendToChangePassword(token: string): void {
    this.$router.push({
      name: "changepassword",
      query: { token: token },
    });
  }

  validate(twoFaToken: string): void {
    if (this.loading == true) return;
    this.loading = true;

    localStorage.getItem("2fa") == "validate_2fa"
      ? validateTwoFaToken({ code: twoFaToken })
          .then((req: any) => {
            saveAuthToken(req.data.token);
            if (this.$route.query.continue) {
              const query: any = this.$route.query;
              this.$router.push({ path: decodeURIComponent(query.continue) });
            } else {
              req.data.ask_for && req.data.ask_for == "change_password"
                ? this.sendToChangePassword(req.data.token)
                : this.$router.push({ name: "my-apps" });
            }
            this.loading = false;
          })
          .catch((e) => {
            if (e.response?.status === 429) {
              this.$emit("snackbar", {
                message: "Muitas tentativas, aguarde alguns instantes",
              });
              return;
            }
            if (e.response) {
              const err = e.response?.data?.errors[0];
              if (err.description == "Password Expired") {
                this.$router.push({
                  name: "changepassword",
                  query: { token: err.data.token_change_password },
                });
                return;
              }
            }
            this.$emit("snackbar", {
              message: "Código incorreto!",
              top: true,
              multiLine: true,
              color: "red",
              textStyle: "font-weight: 600",
              transition: "scroll-y-transition",
              btn: {
                color: "white",
              },
            });
            
          }).finally(() => {
            this.loading = false;
          })
      : activate2FaByUser({
          code: twoFaToken,
          _2fa: "TOTP",
          totp_key: this.totp_key,
        })
          .then((res: any) => {
            saveAuthToken(res.data.token);
            this.$router.push({ name: "my-apps" });
          })
          .catch(() => {
            this.$emit("snackbar", {
              message: "Código incorreto!",
              top: true,
              multiLine: true,
              color: "red",
              textStyle: "font-weight: 600;",
              transition: "scroll-y-transition",
              btn: {
                color: "white",
              },
            });
            this.loading = false;
          });
  }
}
