
import { postFile, uploadAWS } from "@/services/file";
import { mapActions, mapGetters } from "vuex";
import { Component, Vue, Watch } from "vue-property-decorator";
import { sendLogout as logSendLogout } from "@/services/logs";
import { saveAuthToken } from "@/utils/token";
import { toLogin } from '@/utils/redirects'
import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";
import {
  twoFaByUser,
  activate2FaByUser,
  changePassword,
  updateProfilePhoto,
} from "@/services/user";
import { updateUserMe } from '@/services/me'
import { verifyDueDate } from "@/utils/date";

@Component({
  components: {
    Cropper,
  },
  computed: {
    ...mapGetters("meState", {
      me: "me",
    }),
  },
  methods: {
    ...mapActions("meState", ["actionsMe", "actionsSetMe"]),
  },
})
export default class Header extends Vue {
  $refs!: any;
  file: any = null;

  /** Indica se está em progresso a requisição para salvar o usuário. */
  savingUser = false;
  error4017 = false;
  show = true;
  hidden = true;
  dialog = false;
  changeFile = false;
  dialogUser = false;
  dialogCropper = false;
  validFormUser = true;
  validFormChangePassword = true;
  tab = 0;
  activeBtn = 0;
  me: any;
  actionsMe: any;
  actionsSetMe: any;
  img: any = "";
  tempImg: any = "";
  twofaData: any = {};
  isExpired: any = {
    expired: false,
  };
  formUser: any = {
    name: "",
    email: "",
    photo: "",
    old_password: "",
    new_password: "",
    _2fa: false,
  };

  roles = [
    (v: string): boolean => this.isEmpty(v),
    (v: string): boolean => this.isUpperCase(v),
    (v: string): boolean => this.isLowerCase(v),
    (v: string): boolean => this.isNumber(v),
    (v: string): boolean => this.getSpecialCaracter(v),
    (v: string): boolean => this.isLengthTrue(v),
  ];

  // Variáveis do formulário
  value = 0;
  code = "";

  @Watch("file")
  onPropertyChanged(): void {
    if (this.changeFile) {
      this.changeFile = false;
      return;
    }
    var reader = new FileReader();
    reader.readAsDataURL(this.file);
    reader.onload = () => {
      if (reader.result) {
        this.img = reader.result;
        this.dialogCropper = true;
        let inputFileHeader: any = document.getElementById("inputFileHeader");
        inputFileHeader.value = "";
      }
    };
  }

  get smallerThanMd(): boolean {
    if (this.$vuetify.breakpoint.name == "xs" || this.$vuetify.breakpoint.name == "sm") {
      return true;
    }
    return false;
  }

  async created(): Promise<void> {
    try {
      await this.actionsMe();
    } catch (error) {
      console.error(error);
      this.$emit("snackbar", {
        message: "Erro ao carregar as informaçẽos do usuário!",
      });
    }

    if (this.me._2fa !== "TOTP") {
      this.$emit("snackbar", {
        left: true,
        multiLine: true,
        color: "red",
        textStyle: "font-weight: 600;",
        transition: "scroll-x-reverse-transition",
        message:
          "<i class='v-icon notranslate mr-2 mdi mdi-alert-circle-outline theme--dark'></i>Ative o 2FA",
        btn: {
          color: "white",
        },
      });
    }

    this.isExpired = verifyDueDate(this.me.password_due_date);
    if (this.isExpired.expired) {
      setTimeout(() => {
        this.$emit("snackbar", {
          left: true,
          multiLine: true,
          color: "yellow",
          textStyle: "font-weight: 600;",
          transition: "scroll-x-reverse-transition",
          message:
            this.isExpired.expire_in != 0
              ? `<i class='v-icon notranslate mr-2 mdi mdi-alert-circle-outline theme--dark'></i>
            Sua senha vai expirar em ${this.isExpired.expire_in} dias`
              : `<i class='v-icon notranslate mr-2 mdi mdi-alert-circle-outline theme--dark'></i>
            Sua senha vai expirar hoje`,
          btn: {
            color: "white",
          },
        });
      }, 7000);
    }

    if (this.$route.path !== "/my-apps" && this.me.is_admin == true) {
      this.hidden = false;
      this.show = false;
      switch (this.$route.path) {
        case "/admin/users":
          this.activeBtn = 0;
          break;
        case "/admin/profiles":
          this.activeBtn = 1;
          break;
        case "/admin/permissions":
          this.activeBtn = 2;
          break;
        case "/admin/apps":
          this.activeBtn = 3;
          break;
        case "/admin/logs":
          this.activeBtn = 4;
          break;
        default:
          this.activeBtn = 0;
          break;
      }
    }
  }

  showBtn(): void {
    this.show = false;
    this.hidden = false;
    this.activeBtn = 0;
    this.goTo("users");
  }

  goTo(pageName: any, e: any = null): void {
    if (e && e.ctrlKey) {
      let rout: any = this.$router.options.routes?.find((r) => r.name == pageName);
      window.open(rout.path, "_blank");
      setTimeout(() => {
        switch (this.$route.path) {
          case "/admin/users":
            this.activeBtn = 0;
            break;
          case "/admin/profiles":
            this.activeBtn = 1;
            break;
          case "/admin/permissions":
            this.activeBtn = 2;
            break;
          case "/admin/apps":
            this.activeBtn = 3;
            break;
          case "/admin/logs":
            this.activeBtn = 4;
            break;
          default:
            this.activeBtn = 0;
            break;
        }
      });
      return;
    }

    if (this.$route.name == pageName) return;
    this.$router.push({ name: pageName });
  }

  toHome(): void {
    if (this.$route.name == "my-apps") return;
    if (this.me.is_admin == true) {
      this.hidden = true;
      this.show = true;
    }
    this.$router.push({ name: "my-apps" });
  }

  logout(): void {
    if (this.$route.name == "login") return;
    logSendLogout().finally(() => {
      toLogin()
      setTimeout(() => {
        this.actionsSetMe({});
      }, 3000);
    });
  }

  openUser(): void {
    setTimeout(() => {
      this.hidden = !this.hidden;
    }, 1000);
  }

  open2FaDialog(): void {
    this.dialog = true;
    twoFaByUser().then((_2fa: any) => {
      this.twofaData = _2fa.data;
    });
  }

  activate2FaDialog(): void {
    const payload = {
      code: this.code,
      totp_key: this.twofaData.totp_key,
      _2fa: "TOTP",
    };
    activate2FaByUser(payload)
      .then((_2fa: any) => {
        saveAuthToken(_2fa.data.token);
        this.$emit("snackbar", {
          message: "2FA foi ativado",
        });
        this.dialog = false;
        this.me._2fa = "TOTP";
      })
      .catch((error) => {
        console.error(error);
        this.$emit("snackbar", {
          message: "Não foi possivel ativado o 2FA!",
        });
      });
  }

  openChangePassword():void {
    this.tab = 1;
    this.openModalUser();
  }

  openModalUser(): void {
    this.dialogUser = true;

    this.formUser.name = this.me.name;
    this.formUser.email = this.me.email;
    this.formUser.photo =
      this.me.profile_photo && this.me.profile_photo.url ? this.me.profile_photo.url : "";
    this.formUser._2fa = this.me._2fa ? true : false;
  }

  closeModalUser(): void {
    this.dialogUser = false;
    setTimeout(() => {
      this.tab = 0;
      if (this.$refs.formChangePassword) this.$refs.formChangePassword.reset();
    }, 290);
  }

  changeTab(tab: number): void {
    this.tab = tab;
    setTimeout(() => {
      if (this.$refs.formChangePassword) this.$refs.formChangePassword.reset();
    }, 290);
  }

  changePassword(): void {
    if (!this.$refs.formChangePassword.validate()) return;

    if (this.savingUser) return;

    const payload = {
      new_password: this.formUser.new_password,
      password: this.formUser.old_password,
    };

    this.savingUser = true;

    changePassword(payload)
      .then((res: any) => {
        this.$emit("snackbar", {
          message: "Senha alterada com sucesso!",
        });
        this.error4017 = false
        this.closeModalUser();
      })
      .catch((err: any) => {
        const error4017 = err.response.data.errors.find((error: any )=> error.code == 4017)
        if (error4017) {
          this.error4017 = true
        }
        this.$emit("snackbar", {
          message: "Não foi possível alterar a senha",
        });
      }).finally(() => {
        this.savingUser = false;
      });
  }

  updatedUser(): void {
    const payload = { ...this.me };

    payload.password_due_date =
      this.me.password_due_date == "None" ? null : this.me.password_due_date;
    payload.email = this.formUser.email;
    payload.name = this.formUser.name;
    payload.roles = this.me.roles.map((role: any) => role.id);
    payload._2fa = this.formUser._2fa ? 'TOTP' : null

    updateUserMe(payload)
      .then((res: any) => {
        if (this.file != null) {
          const payloadFile = {
            folders: ["profile", "photo"],
            original_name: this.file.name,
            content_type: this.file.type,
            size: this.file.size,
          };
          postFile(payloadFile)
            .then((fileAWS: any) => {
              let formData = new FormData();
              formData.append("Content-Type", fileAWS.data.content_type);
              formData.append("key", fileAWS.data._storage.fields.key);
              formData.append(
                "AWSAccessKeyId",
                fileAWS.data._storage.fields.AWSAccessKeyId
              );
              formData.append("acl", fileAWS.data._storage.fields.acl);
              formData.append("policy", fileAWS.data._storage.fields.policy);
              formData.append("signature", fileAWS.data._storage.fields.signature);
              formData.append("file", this.file);
              uploadAWS(fileAWS.data._storage.url, formData).then(() => {
                this.me.profile_photo = { url: fileAWS.data.url };
                updateProfilePhoto({ profile_photo_id: fileAWS.data.id })
              });
            })
            .catch((err: any) => console.info(err));
        }
        this.$emit("snackbar", {
          message: "Usuário alterado com sucesso!",
        });
        this.me.name = res.data.name;
        this.me.email = res.data.email;
        this.me._2fa = res.data._2fa
      })
      .catch((err: any) => {
        console.info(err);
        this.$emit("snackbar", {
          message: "Erro ao alterar usuário!",
        });
      });

    this.dialogUser = false;
  }

  openFile(): void {
    document.getElementById("inputFileHeader")?.click();
  }

  isEmpty(value: string): boolean {
    return !!value;
  }

  lengthTrue = false;
  isLengthTrue(str: string): any {
    const response = (str + "").length >= 8;

    if (response && !this.lengthTrue) {
      this.value += 20;
      this.lengthTrue = true;
    } else if (!response && this.lengthTrue) {
      this.value -= 20;
      this.lengthTrue = false;
    }
    return response;
  }

  upperTrue = false;
  isUpperCase(str: string): any {
    const result = (str + "").split("").filter((letter: string) => /[A-Z]/.test(letter))
      .length;

    const response = parseInt(result + "") >= 1;

    if (response && !this.upperTrue) {
      this.value += 20;
      this.upperTrue = true;
    } else if (!response && this.upperTrue) {
      this.value -= 20;
      this.upperTrue = false;
    }
    return response;
  }

  lowerTrue = false;
  isLowerCase(str: string): any {
    if (str) {
      const result = str.split("").filter((letter: string) => /[a-z]/.test(letter))
        .length;

      const response = parseInt(result + "") >= 1;

      if (response && !this.lowerTrue) {
        this.value += 20;
        this.lowerTrue = true;
      } else if (!response && this.lowerTrue) {
        this.value -= 20;
        this.lowerTrue = false;
      }

      return response;
    }
    return false;
  }

  numberTrue = false;
  isNumber(str: string): any {
    const result = (str + "").split("").filter((letter: string) => /[0-9]/.test(letter))
      .length;

    const response = parseInt(result + "") >= 1;

    if (response && !this.numberTrue) {
      this.value += 20;
      this.numberTrue = true;
    } else if (!response && this.numberTrue) {
      this.value -= 20;
      this.numberTrue = false;
    }

    return response;
  }

  scTrue = false;
  getSpecialCaracter(str: string): any {
    let regex = /^(?=.*[@!#$%^&*()/\\])[@!#$%^&*()/\\a-zA-Z0-9]$/;
    const result = (str + "").split("").filter((letter: string) => regex.test(letter))
      .length;

    const response = parseInt(result + "") >= 1;

    if (response && !this.scTrue) {
      this.value += 20;
      this.scTrue = true;
    } else if (!response && this.scTrue) {
      this.value -= 20;
      this.scTrue = false;
    }

    return response;
  }

  change({ canvas }: any): void {
    this.tempImg = canvas.toDataURL();
  }

  changeImg(): void {
    this.changeFile = true;
    this.formUser.photo = this.tempImg;
    this.file = this.dataURLtoFile(this.tempImg);
    this.dialogCropper = false;
  }

  dataURLtoFile(dataurl: any): any {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], this.file.name, { type: mime });
  }
}
